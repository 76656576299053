import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import { gmapsStyle } from '../../utils/constants'
import { numberWithSpacesAndCurrency } from '../../localization/number-formatter'
import { Line } from '../DetailBox/DetailContentBox'
import { capitalize } from '@material-ui/core/utils'
import imgHome from '../../images/home.png'
import imgSearch from '../../images/search.png'
import theme from '../../Theme/theme'

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error("google api is already presented")
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '50vh',
    padding: theme.spacing(5),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.spacing(2),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(25),
    marginBottom: theme.spacing(4),
    textTransform: 'uppercase',
    fontFamily: `'Gotham Bold', serif`,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  propertyTitleInfoView: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(10),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  container: {
    flexBasis: '80%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      flexBasis: '80%',
    },
  },
  extraTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  mapDiv: {
    width: '100%',
    height: theme.spacing(70),
  },
}))

interface OffMarketMapProps {
  data: any
  isSmDown: boolean
}

const containerStyle = {
  width: '100%',
  height: '70vh',
}

export const OffMarketMap: React.FC<OffMarketMapProps> = (props) => {
  const classes = useStyles()
  const { data } = props
  const intl = useIntl()
  const [activeMarker, setActiveMarker] = useState(null);
  const [markers, setMarkers] = useState(data.map((property: any) => {
    const val = {
      lat: Number.parseFloat(Number.parseFloat(property.lat).toFixed(3) + (property.id * 0.011 + (Math.round(Math.random()) == 1 ? -1 : 1) * (Math.random() * 0.012))),
      lng: Number.parseFloat(Number.parseFloat(property.lng).toFixed(3) + (property.id * 0.012 + (Math.round(Math.random()) == 1 ? -1 : 1) * (Math.random() * 0.011))),
      isRented: (property?.statutVente == 'LOU_A'),
      ...property
    }
    return val
  }));

  const handleActiveMarker = (marker: React.SetStateAction<null>) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map: { fitBounds: (arg0: google.maps.LatLngBounds) => void }) => {
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(
      (property: { lat: string; lng: string }) =>
        bounds.extend(
          {
            lat: Number.parseFloat(property.lat),
            lng: Number.parseFloat(property.lng)
          }
        )
    );
    map.fitBounds(bounds);
  };

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.heading} container justifyContent="center">
          <MapOutlinedIcon style={{ marginRight: '1em', fontSize: '1.3rem', marginTop: '3px' }} />
          {intl.formatMessage({ id: 'offMarket.headLine' })}
        </Grid>
        <Grid item xs={12} className={classes.mapDiv}>
          <LoadScriptOnlyIfNeeded googleMapsApiKey="AIzaSyAbHDxCpLwdkI0JZWYcV14-zVm6_dFAR5o">
            <GoogleMap 
              mapContainerStyle={containerStyle}
              zoom={8} 
              options={{ 
                disableDefaultUI: true,
                zoomControl : true,
                styles: gmapsStyle,
              }}
              onLoad={handleOnLoad}
              onClick={() => setActiveMarker(null)}
            >
              <MarkerClusterer
                minimumClusterSize={2}
                styles={[
                  {
                    textColor: 'gray',
                    url: imgSearch,
                    height: 32,
                    width: 32
                  },
                 {
                    textColor: 'gray',
                    url: imgSearch,
                    height: 32,
                    width: 32
                  },
                 {
                    textColor: 'gray',
                    url: imgSearch,
                    height: 32,
                    width: 32
                  }
                ]}
              >
                {clusterer =>
                  markers.map((property: any) => (
                    <Marker
                      key={property.id}
                      position={{
                        lat: Number.parseFloat(property.lat),
                        lng: Number.parseFloat(property.lng)
                      }}
                      onClick={() => handleActiveMarker(property.id)}
                      clusterer={clusterer}
                      icon={{
                        url: imgHome,
                        size: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(0, 32),
                      }}
                    >
                      {activeMarker === property.id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                          <Grid justifyContent='center' style={{ maxWidth: '264px', marginLeft: '6px' }} >
                            <Grid 
                              justifyContent='center' 
                              className={classes.propertyTitleInfoView} 
                              style={{ 
                                maxWidth: '260px', 
                                marginLeft: '2px',
                                marginRight: '2px',
                                textAlign: 'center',
                                fontSize: theme.typography.pxToRem(12),
                                marginTop: theme.spacing(1),
                                marginBottom: theme.spacing(1.5),
                              }} 
                              item 
                              xs={12}
                            >
                              {property[`titre${capitalize(intl.locale)}`] ? property[`titre${capitalize(intl.locale)}`] : property.titreFr ? property.titreFr : '' }
                            </Grid>
                            {property.photos[0] && property.photos[0].large && 
                              (<Grid 
                                justifyContent='center' 
                                style={{ 
                                  padding: '2px 0px', 
                                  maxWidth: '100%', 
                                  textAlign: 'center',
                                  fontSize: theme.typography.pxToRem(14),
                                  marginBottom: theme.spacing(0),
                                  marginTop: theme.spacing(0), 
                                }} 
                                item 
                                xs={12}
                              >
                                <img
                                  src={property.photos[0]?.large}
                                  alt=""
                                  style={{ 
                                    width: '100%', 
                                    objectFit: 'cover',
                                    justifyContent: 'center' 
                                  }}
                                  loading="lazy"
                                >
                                </img>
                              </Grid>)
                            }
                            <Grid
                              justifyContent='center'
                              style={{ 
                                maxWidth: '264px', 
                                textAlign: 'center',
                                fontWeight: 450,
                                fontSize: theme.typography.pxToRem(10),
                                marginBottom: theme.spacing(0),
                                marginTop: theme.spacing(.5),
                              }} 
                              container 
                              xs={12}
                            >
                              {property.surfaceHabitable + (property.unitArea == 'M2' ? intl.formatMessage({ id: 'squareMeter' }) : intl.formatMessage({ id: 'squareFeet' }))}
                              <Line />
                              {property.nombreChambres + ' ' + intl.formatMessage({ id: 'chamber' + (property.nombreChambres && property.nombreChambres > 1 ? 's' : '')}).toLowerCase()}
                              <Line />
                              {property.nombrePieces + ' ' + intl.formatMessage({ id: 'piece' + (property.nombrePieces && property.nombrePieces > 1 ? 's' : '')}).toLowerCase()}
                            </Grid>
                            <Grid 
                              justifyContent='center'
                              style={{ 
                                maxWidth: '264px', 
                                textAlign: 'center',
                                fontWeight: 200,
                                fontSize: theme.typography.pxToRem(14),
                                marginTop: theme.spacing(0.2),
                                marginBottom: theme.spacing(1.5),
                              }} 
                              item 
                              xs={12}
                            >
                              {property.ville} 
                            </Grid>
                            <Grid 
                              justifyContent='center'
                              style={{ 
                                maxWidth: '264px', 
                                textAlign: 'center',
                                fontWeight: 150,
                                marginTop: theme.spacing(.8),
                                marginBottom: theme.spacing(.8),
                              }} 
                              item 
                              xs={12}
                            >
                              {!property.prixConfidentiel
                              ? (property.statutVente == 'ALO_A' ? numberWithSpacesAndCurrency(property.prixLoyer, property.unitPrice, false) + intl.formatMessage({ id: 'perMonthsCompact' }) : numberWithSpacesAndCurrency(property.prixAffichageFai, property.unitPrice, false))
                              : intl.formatMessage({ id: 'confidentialPrice' })}
                            </Grid>
                            <Grid 
                              justifyContent='center'
                              style={{ 
                                maxWidth: '264px', 
                                textAlign: 'center',
                                marginTop: theme.spacing(1.5),
                                marginBottom: theme.spacing(0),
                                fontSize: theme.typography.pxToRem(10),
                              }} 
                              item 
                              xs={12}
                            >
                              {`${intl.formatMessage({ id: 'contactAgentOffMarket' })} ${capitalize(property.suiviPar.name)}`}
                              {`${intl.formatMessage({ id: 'byPhoneTo' })} ${property.suiviPar.phone} ${intl.formatMessage({ id: 'byEmailTo' })} ${property.suiviPar.email}`}.
                            </Grid>
                          </Grid>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  ))  
                }
              </MarkerClusterer>
            </GoogleMap>
          </LoadScriptOnlyIfNeeded>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OffMarketMap
